.surf-contest-template {
    background-color: #000000;

    @media screen and (max-width: 600px) {
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;

        Button {
            display: none;
        }

        .left-art,
        .right-art {
            display: none;
        }
    }

    .left-art {
        position: fixed;
        left: 0;
        text-align: left;
        margin-left: -15rem;

        img {
            max-width: 35%;
        }
    }

    .right-art {
        position: fixed;
        right: 0;
        text-align: right;
        margin-right: -15rem;

        img {
            max-width: 35%;

        }
    }

    .surfboard-button {
        width: 270px;
        height: 100px;
        position: relative;

        &:hover {
            background-color: transparent;
            opacity: 100%;
            transition: .5s;
        }

        p {
            position: absolute;
            top: 35%;
            text-align: center;

            font-size: .8rem;
            font-weight: 400 !important;
            color: white;
        }

        &.second {
            p {
                top: 40%;
            }
        }
    }


    .event-image {
        display: flex;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 600px) {
            height: 660px;
            width: 100%;
        }

        img {
            width: 80%;
            border-radius: 20px;

            @media screen and (max-width: 600px) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0;
            }
        }

        .image-overlay-info {
            color: white;
            position: absolute;
            top: 65%;
            bottom: 35%;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                margin: 0;
                padding: 0;
                font-size: 2.6rem;
                font-weight: 300 !important;
                text-transform: uppercase;
            }

            h5 {
                margin: 0;
                padding: 0;
                font-size: 1.4rem;
                font-weight: 300 !important;
            }

        }
    }


    .main-event-info {
        width: 70%;
        @media screen and (max-width: 600px) {
            width: 100%;
            text-align: center;
        }

        h4 {
            margin: 0;
            padding: 0;
            font-size: 1.4rem;
            font-weight: 300 !important;
        }

        h1 {
            margin: 0;
            padding: 0;
            font-size: 2.3rem;
            font-weight: 500 !important;
            text-transform: uppercase;
        }

        p {
            display: flex;
            justify-content: center;
            text-align: center;

            p {
                margin-top: 7px;
                width: 50%;
                font-size: .7rem;
                font-weight: 300 !important;
            }
        }
    }

    .three-sections {
        width: 55%;

        @media screen and (max-width: 500px) {
            width: 100%;
        }

        .section {
            @media screen and (max-width: 600px) {
                margin: 10px 0;

                .icon {
                    height: 50px;
                    width: 50px;
                }

                h3 {
                    font-size: 1.2rem;
                }

                p {
                    font-size: 1rem;
                }
            }

        }

        h3 {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-weight: 500 !important;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: .8rem;
            font-weight: 300 !important;
        }

        .icon {
            color: '#e0023f';
            height: 30px;
            width: 30px;
        }
    }

    .extra-info {
        margin-top: 3rem;
        width: 55%;

        @media screen and (max-width: 600px) {
            width: 85%;
        }

        h4 {
            margin-bottom: 15px;
            padding: 0;
            font-size: 1.3rem;
            font-weight: 500 !important;
        }

        p {
            width: 80%;
            margin: 0;
            padding: 0;
            font-size: .7rem;
            font-weight: 300 !important;
        }

    }

    .logo-box {
        width: 55%;
        margin-top: 60px;

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        img {
            max-width: 110px;
            max-height: 110px;
            height: auto;
            width: auto;

            @media screen and (max-width: 600px) {
                max-width: 80px;
                max-height: 80px;
                height: auto;
                width: auto;
            }
        }

        .logo-style {
            display: flex;
            justify-content: center;
            align-content: center;
        }
    }

    .mobile-footer {
        display: none;
        width: 100%;
        background-color: #fb5d8c;

        .sticky {
            color: white;

            h3 {
                margin: 0;
                margin-bottom: 1rem;
                padding: 3px 0;
                font-size: 1.3rem;
                font-weight: 300 !important;
            }
        }

        @media screen and (max-width: 600px) {
            display: flex;
            position: fixed;
            bottom: 40px;
            left: 0;

            Button {
                display: flex;
            }
        }
    }
}

.event-info-snippet {
    background-color: #fb5d8c;
    border-radius: 10px;
    padding: 10px 0;

    color: #ffffff;

    @media screen and (max-width: 600px) {
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        border-radius: 0;

        .event-date-time {
            flex-direction: column;

            p {
                font-size: .8rem !important;
            }
        }
    }

    img {
        height: 75px;
        width: 75px;
        object-fit: cover;
        border-radius: 50%;
    }

    .event-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .event-title {
            margin: 3px 0;

            p {
                font-size: 1rem;
                font-weight: 500 !important;
            }
        }

        .event-date-time {
            margin: 3px 0;
            padding-right: 50px;
            width: 90%;
            justify-content: space-between;

            p {
                font-size: .7rem;
                font-weight: 300 !important;
            }

            .icon {
                height: 15px;
                width: 15px;
            }

            .locations {
                display: none;

                @media screen and (max-width: 600px) {
                    display: flex;
                }
            }
        }

        .registration {
            background-color: #ffffff;
            color: #23b2c2;
            border-radius: 40px;
            margin-top: 8px;

            p {
                padding: 3px 15px;
                font-size: .8rem;
                font-weight: 500 !important;
            }
        }
    }
}

.event-registration-checkout {
    margin-top: 20px !important;
    border-radius: 20px;
    box-shadow: 0 0 4px 1px #444444;
    padding: 15px 7px;

    @media screen and (max-width: 600px) {
        margin-top: 160px !important;
    }
}

.public-cart{
    @media screen and (max-width: 600px) {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
}
